var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"back":""}},[_c('el-row',[_c('el-col',{attrs:{"span":20}},[_c('el-form',{ref:"addForm",attrs:{"size":"small","model":_vm.formModel,"rules":_vm.formRules}},[_c('el-form-item',[_c('el-button',{staticClass:"ma-t",attrs:{"type":"success","icon":"el-icon-plus","size":"small"},on:{"click":_vm.onAddItem}},[_vm._v(" 新增 ")]),_c('el-tabs',{staticClass:"ma-t",attrs:{"type":"card","closable":"","before-leave":_vm.onTabsLeave},on:{"tab-remove":_vm.removeTab},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.detailList),function(item,index){return _c('el-tab-pane',{key:index,attrs:{"label":item.pageName,"name":index}},[_c('el-form-item',{attrs:{"prop":"pageName","label":"页面名称"}},[_c('el-input',{model:{value:(item.pageName),callback:function ($$v) {_vm.$set(item, "pageName", $$v)},expression:"item.pageName"}})],1),_c('el-form-item',{attrs:{"label":"是否启用"}},[_c('el-switch',{model:{value:(item.isAvailable),callback:function ($$v) {_vm.$set(item, "isAvailable", $$v)},expression:"item.isAvailable"}})],1)],1)}),1),_c('PageDecorator',{ref:"pageDecorator",attrs:{"available-components":[
                            'Divider',
                            'Toolbar',
                            'Ad',
                            'AdCube',
                            'MagicCube',
                            'RichText',
                            'CustomCode',
                            'Video' ],"json":_vm.detailList[_vm.tabIndex].pageContent}})],1),_c('el-form-item',{attrs:{"label-width":"13em"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.onConfirm}},[_vm._v(" 保 存 ")]),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" 返 回 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }